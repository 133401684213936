import React, { useRef, useState } from "react"
import { Router, Location } from "@reach/router"
import { navigate } from "gatsby"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import PersonalInfoForm from "@components/_molecules/form_personal_info"
import AddressForm from "@components/_molecules/form_address"
import ParentEmploymentForm from "@components/_molecules/form_employment_status"
import StatementForm from "@components/_molecules/form_statements"
import StudentInfoForm from "@components/_molecules/form_student_info"
import StudentAddressForm from "@components/_molecules/child_confirm"
import ApplicationReview from "@components/_molecules/application_review"
import SetupAccount from "@components/SetupAccount"
import axios, { AxiosRequestConfig } from "axios"
import * as headers from "@data/headers"
import { URL_PARENT_DASHBOARD } from "@data/constants"
import { localStorageInitQueryKeys } from "@services/LocalStorage"

const ParentRegistration = () => {
  localStorageInitQueryKeys()

  const {
    gettingStarted,
    adultInfo,
    studentInfo,
    reviewApplication,
    statementForm,
    employmentForm,
  } = data
  gettingStarted.backLinkNative = true

  const [currentStep, setCurrentStep] = useState("")
  const setNextStep = (nextStep: string) => {
    setCurrentStep(nextStep)
  }

  const checkUser = () => {
    const options: AxiosRequestConfig = {
      method: "POST",
      headers: headers.getHeaders(),
      url: headers.api,
      data: JSON.stringify({
        query: `query {
          user {
            id
            registrationCompletedAt
            userType
          }
        }`,
      }),
    }

    axios(options)
      .then((res) => {
        if (res.data.errors) {
          console.log("failed checkUser() data.errors")
        } else if (
          res.data.data.user &&
          res.data.data.user.userType &&
          res.data.data.user.userType === "custodian" &&
          res.data.data.user.registrationCompletedAt &&
          typeof res.data.data.user.registrationCompletedAt === "string"
        ) {
          window.location.href = URL_PARENT_DASHBOARD
        }
      })
      .catch(() => {
        console.log("failed checkUser() catch")
      })
  }

  // component will mount 'hack'
  const willMount = useRef(true)
  if (willMount.current) {
    checkUser()
  }

  return (
    <Layout type="gray" pageClass="pledge">
      <SEO title="Parent Registration" />
      <Location>
        {({ location }) => (
          <Router basepath="/parent-registration">
            <SetupAccount
              path="/"
              data={gettingStarted}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
              nextForm="/parent-registration/personal-information"
            />
            <PersonalInfoForm
              path="/personal-information"
              data={adultInfo}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
              nextForm={"/parent-registration/address"}
              trackScreenViewEvent="screen_view.support_student_adult_info"
              trackButtonTapEvent="button_tap.support_student_adult_info_next"
              trackLearnMoreEvent="button_tap.support_student_adult_info_learnmore"
              trackItinEvent="button_tap.support_student_ITIN"
            />
            <AddressForm
              path="/address"
              data={adultInfo}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
              nextForm="/parent-registration/student-info"
              trackScreenViewEvent="screen_view.support_student_adult_info_address"
              trackButtonTapEvent="button_tap.support_student_adult_info_address_next"
            />
            <StudentInfoForm
              path="/student-info"
              headingData={studentInfo.heading}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
              nextForm="/parent-registration/employment"
            />
            <ParentEmploymentForm
              path="/employment"
              headingData={employmentForm.heading}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
              nextForm="/parent-registration/verify-statements"
            />
            <StatementForm
              path="/verify-statements"
              data={statementForm}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
              nextForm="/parent-registration/student-citizenship"
            />
            <StudentAddressForm
              path="/student-citizenship"
              headingData={data.studentCitizenship.heading}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
              nextForm="/parent-registration/verify-info"
            />
            <ApplicationReview
              path="/verify-info"
              headingData={reviewApplication.heading}
              currentStep={currentStep}
              setNextStep={setNextStep}
              location={location}
              navigate={navigate}
            />
          </Router>
        )}
      </Location>
    </Layout>
  )
}

export default ParentRegistration
