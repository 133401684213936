import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import { Link } from "gatsby"
import FormButton from "@components/_atoms/button_form_input_submit"
import RegSuccess from "@components/_molecules/splash_link_success"
import * as errors from "@services/errors"
import LAEJS from "@services/LAEJS"
import { EVENT_TOKEN_DW_APP_SUBMITTED } from "@data/tracking_adjust"
import HeaderAndText from "@components/header_with_text"
import Divider from "@components/_atoms/divider"
import Toggle from "@components/_atoms/toggle_app_review"
import BackButton from "@components/back_button"
import HiddenErrorHandler from "@components/_atoms/HiddenErrorHandler"

class ApplicationReview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonSubmitted: false,
      name: "",
      employment: "",
      Address: {
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: "",
        generalError: null,
      },
      childName: "",
      childAddress: {
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: "",
      },
      childEmail: "None",
      checkValue: false,
      regSuccess: false,
    }
  }

  componentDidMount = () => {
    LAEJS.track("screen_view.parent_review_application")
    let {
      name,
      employmentStatus,
      Address,
      childName,
      childAddress,
      email,
      childEmail,
    } = localStorage
    if (
      name &&
      employmentStatus &&
      Address &&
      childName &&
      childAddress &&
      email
    ) {
      let pa = JSON.parse(Address)
      let ca = JSON.parse(childAddress)

      this.setState({
        name: name,
        email: email,
        employment: employmentStatus,
        Address: {
          street: pa.address1,
          apt: pa.address2,
          city: pa.city,
          state: pa.state,
          zip: pa.postalCode,
        },
        childName: childName,
        childAddress: {
          street: ca.address1,
          apt: ca.address2,
          city: ca.city,
          state: ca.state,
          zip: ca.postalCode,
        },
      })
      if (childEmail) {
        this.setState({ childEmail: childEmail })
      }
    }
  }
  handleCheck = () => {
    if (this.state.checkValue) {
      this.setState({ checkValue: false })
    } else {
      this.setState({ checkValue: true })
    }
  }
  handleSubmit = (ev) => {
    ev.preventDefault()
    this.setState({ buttonSubmitted: true })
  }

  checkStatus = () => {
    setTimeout(() => {
      let _this = this,
        accountStatusQuery = {
          query: `query {
          investmentAccounts {
            id
            status
          }
        }`,
          variables: {},
        }
      const options = {
        method: "POST",
        data: JSON.stringify(accountStatusQuery),
        headers: headers.getHeaders(),
        url: headers.api,
      }
      axios(options)
        .then((res) => {
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            let { status } = res.data.data.investmentAccounts[0]

            if (status === "provisioning") {
              _this.checkStatus()
            } else if (status === "open") {
              LAEJS.jsTrackAdjustEvent({
                json: {
                  eventToken: EVENT_TOKEN_DW_APP_SUBMITTED,
                },
              })
              _this.completeRegistration()
            } else {
              localStorage.setItem("dwStatus", "retry")
              localStorage.setItem(
                "advisorSysAccountId",
                res.data.data.investmentAccounts[0].id
              )
              _this.setState({
                generalError: "There was a problem processing your information",
                buttonSubmitted: false,
              })
            }
          }
        })
        .catch((error) => {
          localStorage.setItem("dwStatus", "retry")
          _this.errorHandler(error)
        })
    }, 2000)
  }

  completeRegistration = () => {
    this.setState({ regSuccess: true })
    window.scrollTo(0, 0)
    let $wrapper = document.querySelector("#gatsby-focus-wrapper")
    $wrapper.style.backgroundColor = "#ffa217"
    $wrapper.style.overflow = "hidden"
    $wrapper.style.height = "100vh"
    localStorage.removeItem("email")
    localStorage.removeItem("name")
    localStorage.removeItem("dob")
    localStorage.removeItem("Address")
    localStorage.removeItem("citizenshipCountry")
    localStorage.removeItem("employmentStatus")
    localStorage.removeItem("childAddress")
    localStorage.removeItem("childEmail")
    localStorage.removeItem("childName")
    localStorage.removeItem("childDob")
    localStorage.removeItem("beneficiaryId")
    this.props.setNextStep("Registration Complete")
    window.location = `/app/`
  }

  errorHandler = (err) => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err),
    })
  }

  render() {
    return (
      <React.Fragment>
        <BackButton navigate={this.props.navigate} />
        <HeaderAndText data={this.props.headingData} />
        <div>
          {!this.state.regSuccess ? (
            <div className="application-review-section">
              <div className="app-review">
                <div className="links">
                  <p>Your Name</p>
                  <Link to="/parent-registration/">{this.state.name}</Link>
                </div>
                <Divider />
                <div className="links">
                  <p>Employment Status</p>
                  <Link
                    to="/parent-registration/employment"
                    style={{ textTransform: `capitalize` }}
                  >
                    {this.state.employment === "SELF_EMPLOYED"
                      ? "Self-Employed"
                      : this.state.employment.toLowerCase()}
                  </Link>
                </div>
                <Divider />
                <div className="links address">
                  <p>Your Address</p>
                  <Link className="address" to="/parent-registration/address">
                    {this.state.Address.street} {this.state.Address.apt}
                    <br />
                    {this.state.Address.city}, {this.state.Address.state}{" "}
                    {this.state.Address.zip}
                  </Link>
                </div>
                <Divider />
                <div className="links">
                  <p>Your email</p>
                  <Link
                    to="/parent-registration/"
                    style={{ textTransform: `capitalize` }}
                  >
                    {this.state.email}
                  </Link>
                </div>
                <Divider />
                <div className="links">
                  <p>Student's Name</p>
                  <Link to="/parent-registration/student-info/">
                    {this.state.childName}
                  </Link>
                </div>
                <Divider />
                <div className="links ">
                  <p>Student's Address</p>
                  <Link
                    className="address"
                    to="/parent-registration/student-citizenship/"
                  >
                    {this.state.childAddress.street}{" "}
                    {this.state.childAddress.apt}
                    <br />
                    {this.state.childAddress.city},{" "}
                    {this.state.childAddress.state}{" "}
                    {this.state.childAddress.zip}
                  </Link>
                </div>
                <Divider />
                <div className="links">
                  <p>Student's email</p>
                  <Link to="/parent-registration/student-citizenship/">
                    {this.state.childEmail !== null ||
                    this.state.childEmail !== ""
                      ? this.state.childEmail
                      : "None"}
                  </Link>
                </div>
              </div>
              <div className="tos">
                <p>
                  By clicking the “Confirm” button below, I acknowledge it is
                  equivalent to my written signature and I understand that by
                  doing so I am entering into legal agreements.
                </p>
                <p>
                  I have carefully read, understand, and agree to the terms and
                  provisions of Learn &#38; Earn’s{" "}
                  <a
                    href="https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/94325f84-c9ba-4552-a7a6-96c9e04998f9/article/33423361"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://learnandearnsupport.atlassian.net/servicedesk/customer/portal/2/topic/7a83c556-8146-463d-8bfc-3ee24684b691/article/33292337"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  .
                </p>
                <p>
                  I have carefully read, understand, and agree to the terms and
                  provisions of Ant Money Advisors’{" "}
                  <a
                    href="https://www.antmoneyadvisors.com/advisory-agreement/"
                    target="_blank"
                  >
                    Advisory Agreement
                  </a>
                  , and{" "}
                  <a
                    href="https://www.antmoneyadvisors.com/privacy-policy/"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>{" "}
                  and acknowledge the receipt of Ant Money Advisor’s Form ADV
                  Part 2A{" "}
                  <a
                    href="https://www.antmoneyadvisors.com/wrap-fee-brochure-adv-part-2a/"
                    target="_blank"
                  >
                    Wrap Fee Brochure
                  </a>
                  , Part 2B{" "}
                  <a
                    href="https://www.antmoneyadvisors.com/brochure-supplement-adv-part-2b/"
                    target="_blank"
                  >
                    Supplemental Brochure
                  </a>
                  , and{" "}
                  <a
                    href="https://www.antmoneyadvisors.com/form-crs-adv-part-3/"
                    target="_blank"
                  >
                    Form CRS
                  </a>
                  .
                </p>
                <p>
                  I understand that all investing involves risk, including the
                  possible loss of my investment and that the Core and Explore
                  portfolios are designed for investors seeking market returns
                  over the long term. I understand that my investment
                  performance is not guaranteed by Ant Money Advisors, Learn &
                  Earn, nor the State of Delaware or California or any of its
                  officials or employees, nor any federal agency including the
                  FDIC.
                </p>
                <embed
                  src="https://apps.drivewealth.com/disclosures"
                  style={{ height: `130vh` }}
                ></embed>
              </div>
              <form onSubmit={this.handleSubmit}>
                <div className="confirm">
                  <p>I have read and agree to the terms provided.</p>
                  <Toggle
                    data={{ value: this.state.checkValue }}
                    handleCheck={this.handleCheck}
                  />
                </div>
                <HiddenErrorHandler error={this.state.generalError} />
                <FormButton
                  text="Confirm"
                  isDisabled={
                    this.state.buttonSubmitted || !this.state.checkValue
                  }
                  onClick={() =>
                    LAEJS.track("button_tap.parent_review_application_confirm")
                  }
                />
              </form>
            </div>
          ) : (
            <RegSuccess msg="Application Submitted!" />
          )}
        </div>
      </React.Fragment>
    )
  }
}

export default ApplicationReview
