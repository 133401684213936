import React, { Component, FormEvent } from "react"
import "@components/getting_started/style.scss"
import FormButton from "@components/_atoms/button_form_input_submit"
import ListItem from "@components/_atoms/custom_list_item"
import { Headline2C } from "@components/_atoms/Text"
import * as beneficiaryToken from "@services/beneficiaryToken"
import SecurityModal from "@components/_molecules/modal_security"
import BackButton from "@components/back_button"
import LAEJS from "@services/LAEJS"
import { IOnboardingPages } from "@interfaces"
import * as progress from "@services/progress"

const isClient = typeof window !== "undefined"

interface IComponentState {
  ask: boolean
}

class SetupAccount extends Component<IOnboardingPages, IComponentState> {
  constructor(props: IOnboardingPages) {
    super(props)
    this.state = {
      ask: false,
    }
  }
  componentDidMount = () => {
    beneficiaryToken.setToken()
    LAEJS.track("screen_view.support_student_setup_account")

    if (!this.props.setNextStep) {
      //Checks if user has already completed this part. If they have, they will be redirected
      let regPosition = progress.getNext()
      if (regPosition !== "register" && isClient) {
        window.location.href = `/pledge/register/${regPosition}`
      }
    }
  }

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (this.props.setNextStep) {
      this.props.setNextStep("personal-information")
      this.props.navigate(this.props.nextForm)
    } else {
      progress.updateProgress()
      window.location.href = `/pledge/register/${progress.getNext()}`
    }
  }

  handleOK = () => {
    this.setState({ ask: false })
  }
  modal = () => {
    LAEJS.track("button_tap.utma/ugma_faq")
    this.setState({ ask: true })
  }
  render() {
    return (
      <div className="getting-started">
        {this.props.navigate && this.props.data && (
          <BackButton backLinkNative={this.props.data.backLinkNative} />
        )}
        {this.state.ask && (
          <SecurityModal
            data={{
              title: "What is an UTMA/UGMA?",
              copy: "UTMA/UGMA accounts are financial investment accounts that allow adults over the age of 18 to open an account for a child, to help their money grow while they do.",
            }}
            onConfirm={this.handleOK}
          />
        )}
        <Headline2C className="heading-spacing">Setup an account</Headline2C>
        <p className="sub-heading">
          In less than 2 minutes, we’ll get your student’s{" "}
          <span className="link" onClick={this.modal}>
            UTMA/UGMA
          </span>{" "}
          account setup so they can learn and earn.
        </p>

        <ListItem
          style="emoji-list your-info-emoji"
          heading="Your info"
          text="Address, DOB & Last 4 digits of SSN"
        />
        <ListItem
          style="emoji-list student-info-emoji"
          heading="Student info"
          text="Student's DOB & SSN"
        />
        <div style={{ width: `100%`, height: `3rem` }}></div>
        <form onSubmit={this.handleSubmit}>
          <FormButton
            text="Get Started"
            onClick={() =>
              LAEJS.track("button_tap.support_student_setup_account")
            }
          />
        </form>
      </div>
    )
  }
}

export default SetupAccount
