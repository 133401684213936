import React, { Component } from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import HiddenErrorHandler from "@components/_atoms/HiddenErrorHandler"
import { SlideDown } from "react-slidedown"
import Checkbox from "@components/_atoms/button_checkbox"
import "@components/_atoms/_style/slidedown.scss"
import { validate } from "@data/validations"
import * as errors from "@services/errors"
import InputPlaces from "@components/_molecules/input_places"
import SelectCitizenship from "@components/_atoms/select_citizenship"
import { countries } from "@data/constants/countries"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import LAEJS from "@services/LAEJS"

class ChildConfirm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      emailHelpText: "Parent’s email",
      emailErrorStatus: false,
      childEmail: "",
      childEmailHelpText: "This is optional",
      childErrorStatus: false,
      citizenship: "USA",
      address: "",
      streetaddress: "",
      streetHelpText: "Missing Address",
      streetErrorStatus: false,
      apt: "",
      aptHelpText: "Missing apt",
      aptErrorStatus: false,
      city: "",
      cityHelpText: "Missing City",
      cityErrorStatus: false,
      state: "",
      stateHelpText: "Missing State",
      stateErrorStatus: false,
      zipcode: "",
      zipCodeHelpText: "Invalid zip code",
      zipCodeErrorStatus: false,
      generalError: null,
      buttonSubmitted: false,
      addressIsChecked: true,
      notSameIsChecked: false,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    LAEJS.track("screen_view.support_student_confirm_emails")
    let { childAddress, Address, email, childEmail } = localStorage
    if (childEmail) {
      this.setState({
        childEmail: childEmail,
      })
    }
    if (email) {
      this.setState({
        email: email,
      })
    }

    if (typeof childAddress !== `undefined` && childAddress !== Address) {
      let addrObj = JSON.parse(childAddress)
      this.setState({
        address: "no",
        streetaddress: addrObj.address1 || ``,
        apt: addrObj.address2 || ``,
        city: addrObj.city || ``,
        state: addrObj.province || ``,
        zipcode: addrObj.postalCode || ``,
      })
    } else {
      this.setState({
        address: "yes",
      })
    }
  }

  handleChange = (event) => {
    const target = event.target
    const value = target.value
    let name = target.name

    switch (name) {
      case "youremail":
        name = "email"
        break

      case "student'semail":
        name = "childEmail"
        break
    }

    this.setState({
      [name]: value,
      emailErrorStatus: "",
    })
  }
  handlePlacesChange = (state) => {
    this.setState({
      state: state.state,
      city: state.city,
      zipcode: state.zipcode,
      streetaddress: state.streetaddress,
    })
  }
  toggleCheckboxChange = (ev) => {
    if (ev.target.name === "sameAddress" && ev.target.checked === true) {
      //if 'none' is checked, uncheck all except none
      this.setState({
        addressIsChecked: true,
        notSameIsChecked: false,
      })
    } else {
      //otherwise, check the option chosen and ensure 'none' is unchecked
      this.setState({
        [ev.target.name]: ev.target.checked,
        addressIsChecked: false,
      })
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault()

    if (this.state.childEmail && !validate.email.test(this.state.childEmail)) {
      this.errorHandler("Invalid Email")
      return
    } else {
      this.setState({
        generalError: null,
      })
    }

    if (this.state.addressIsChecked === true) {
      let pa = JSON.parse(localStorage.Address)
      this.setState(
        {
          streetaddress: pa.address1,
          apt: pa.address2,
          city: pa.city,
          state: pa.province,
          zipcode: pa.postalCode,
        },
        this.sendData
      )
    } else {
      let streetErrorStatus = this.state.streetaddress === "",
        cityErrorStatus = this.state.city === "",
        stateErrorStatus = !validate.state.test(this.state.state),
        zipCodeErrorStatus = !validate.zipCode.test(this.state.zipcode)
      this.setState({
        streetErrorStatus,
        cityErrorStatus,
        stateErrorStatus,
        zipCodeErrorStatus,
      })
      if (
        !streetErrorStatus &&
        !cityErrorStatus &&
        !stateErrorStatus &&
        !zipCodeErrorStatus
      ) {
        this.sendData()
      }
    }
  }

  sendData = () => {
    this.setState({ buttonSubmitted: true })
    let _this = this
    let beneficiaryId =
      typeof window !== "undefined" ? localStorage.beneficiaryId : ""
    if (_this.state.childEmail) {
      let query = {
        query: `mutation($updateBeneficiaryUserInput: UpdateBeneficiaryUserInput!){
          updateBeneficiaryUser(input: $updateBeneficiaryUserInput){
            user {
              id
              email
              address1
              address2
              city
              province
              postalCode
              country
            }
          }
        }`,
        variables: `{
        	"updateBeneficiaryUserInput": {
        	  "beneficiaryUserId": "${beneficiaryId}",
            "user": {
              "email":"${_this.state.childEmail}",
              "address1": "${_this.state.streetaddress}",
               "address2": "${_this.state.apt}",
               "city": "${_this.state.city}",
               "province": "${_this.state.state}",
               "postalCode": "${_this.state.zipcode}",
               "country": "${_this.state.citizenship}"
            }
          }
        }`,
      }
      const options = {
        method: "POST",
        data: JSON.stringify(query),
        headers: headers.getHeaders(),
        url: headers.api,
      }
      axios(options)
        .then((res) => {
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            let childAddress = {
              address1: this.state.streetaddress,
              address2: this.state.apt,
              city: this.state.city,
              province: this.state.state,
              postalCode: this.state.zipcode,
            }
            localStorage.setItem("childEmail", this.state.childEmail)
            localStorage.setItem("childAddress", JSON.stringify(childAddress))
            this.props.setNextStep("address")
            this.props.navigate(this.props.nextForm)
          }
        })
        .catch((error) => {
          _this.setState({ buttonSubmitted: false })
        })
    } else {
      let noEmailQuery = {
        query: `mutation($updateBeneficiaryUserInput: UpdateBeneficiaryUserInput!){
          updateBeneficiaryUser(input: $updateBeneficiaryUserInput){
            user {
              id
              email
              address1
              address2
              city
              province
              postalCode
              country
            }
          }
        }`,
        variables: `{
        	"updateBeneficiaryUserInput": {
        	  "beneficiaryUserId": "${beneficiaryId}",
            "user": {
              "email": null,
              "address1": "${_this.state.streetaddress}",
               "address2": "${_this.state.apt}",
               "city": "${_this.state.city}",
               "province": "${_this.state.state}",
               "postalCode": "${_this.state.zipcode}",
               "country": "${_this.state.citizenship}"
            }
          }
        }`,
      }
      const options = {
        method: "POST",
        data: JSON.stringify(noEmailQuery),
        headers: headers.getHeaders(),
        url: headers.api,
      }
      axios(options)
        .then((res) => {
          console.log(res)
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            let childAddress = {
              address1: this.state.streetaddress,
              address2: this.state.apt,
              city: this.state.city,
              province: this.state.state,
              postalCode: this.state.zipcode,
            }
            localStorage.setItem("childEmail", this.state.childEmail)
            localStorage.setItem("childAddress", JSON.stringify(childAddress))
            this.props.setNextStep("address")
            this.props.navigate("/parent-registration/verify-info")
          }
        })
        .catch((error) => {
          _this.setState({ buttonSubmitted: false })
        })
    }
  }

  errorHandler = (err) => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err),
    })
  }
  countryOptions = (option, index) => (
    <option key={index} value={option.value}>
      {option.name}
    </option>
  )

  isDisabled = () => {
    if (this.state.buttonSubmitted) {
      return true
    } else {
      return false
    }
  }

  isTheSame = () => {
    if (this.state.address === "no") {
      return false
    } else {
      return true
    }
  }

  render() {
    return (
      <React.Fragment>
        <BackButton navigate={this.props.navigate} />
        <HeaderAndText data={this.props.headingData} />
        <div className="child-confirm">
          <form onSubmit={this.handleSubmit} onChange={this.handleChange}>
            <InputLabelState
              data={{
                name: `Your Email`,
                type: `text`,
                helpText: this.state.emailHelpText,
                errorStatus: this.state.emailErrorStatus,
                defaultVisibility: 0,
                value: this.state.email,
              }}
              onChange={this.handleChange}
            />
            <InputLabelState
              data={{
                name: `Student's Email`,
                type: `text`,
                helpText: this.state.childEmailHelpText,
                errorStatus: this.state.childEmailErrorStatus,
                defaultVisibility: 1,
                value: this.state.childEmail,
              }}
              onChange={this.handleChange}
            />
            <p className="title-text">Student's citizenship</p>

            <SelectCitizenship
              value={this.state.citizenship}
              name="citizenship"
              onChange={this.handleChange}
            >
              {countries.map((arr, index) => this.countryOptions(arr, index))}
            </SelectCitizenship>
            <div>
              <p className="title-text">Student's residence</p>
              <Checkbox
                type="checkbox"
                name="sameAddress"
                checked={this.state.addressIsChecked}
                onChange={this.toggleCheckboxChange}
                label={"Student's address same as mine"}
              />
            </div>
            <SlideDown closed={this.state.addressIsChecked ? true : false}>
              <div>
                <div className="side-by-side">
                  <InputPlaces handlePlacesChange={this.handlePlacesChange} />
                  <div>
                    <InputLabelState
                      data={{
                        name: `Apt`,
                        type: `text`,
                        helpText: this.state.aptHelpText,
                        errorStatus: this.state.aptErrorStatus,
                        defaultVisibility: 0,
                        value: this.state.apt,
                      }}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <InputLabelState
                  data={{
                    name: `City`,
                    type: `text`,
                    helpText: this.state.cityHelpText,
                    errorStatus: this.state.cityErrorStatus,
                    defaultVisibility: 0,
                    value: this.state.city,
                  }}
                  onChange={this.handleChange}
                />
                <div className="side-by-side">
                  <InputLabelState
                    data={{
                      name: `State`,
                      type: `text`,
                      helpText: this.state.stateHelpText,
                      errorStatus: this.state.stateErrorStatus,
                      defaultVisibility: 0,
                      value: this.state.state,
                    }}
                    onChange={this.handleChange}
                  />
                  <InputLabelState
                    data={{
                      name: `Zip Code`,
                      type: `text`,
                      helpText: this.state.zipCodeHelpText,
                      errorStatus: this.state.zipCodeErrorStatus,
                      defaultVisibility: 0,
                      value: this.state.zipcode,
                    }}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
            </SlideDown>

            <HiddenErrorHandler
              error={this.state.generalError}
              text="I am authorizing the student to participate in the Junior Achievement Learn & Earn program which may include a $1/month basic course fee"
            />
            <FormButton
              isDisabled={this.isDisabled()}
              onClick={() =>
                LAEJS.track("button_tap.support_student_confirm_emails")
              }
            />
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default ChildConfirm
