import React from "react"
import "@components/form_child_info/style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import HiddenErrorHandler from "@components/_atoms/HiddenErrorHandler"
import HeaderAndText from "@components/header_with_text"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import InputDate from "@components/_molecules/input_date"
import InputPhone from "@components/_molecules/input_phone"
import { validate } from "@data/validations"
import * as errors from "@services/errors"
import BackButton from "@components/back_button"
import LAEJS from "@services/LAEJS"

class StudentInfoForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      legalname: "",
      legalnameErrorText: "Needs both first and last name",
      legalnameErrorStatus: false,
      phonenumber: "",
      phonenumberErrorStatus: false,
      phonenumberErrorText: "Invalid phone number",
      ssn: "",
      ssnErrorText: "Invalid SSN or ITIN",
      ssnErrorStatus: false,
      itin: "",
      itinErrorText: "Invalid ITIN",
      itinErrorStatus: false,
      dob: "",
      dobErrorText: "Invalid date",
      dobErrorStatus: false,
      buttonSubmitted: false,
      generalError: null,
      token: "",
      showSsn: true,
    }
  }

  componentDidMount() {
    LAEJS.track("screen_view.support_student_child_info")
    let _this = this
    let { childName, childDob, beneficiaryToken } = localStorage
    this.setState({
      legalname: childName || ``,
      dob: childDob || ``,
      beneficiaryToken,
    })
    let beneficiaryIdQuery = {
      query: `query {
        user {
          beneficiaries {
            id
            phone
          }
        }
      }`,
    }
    const options = {
      method: "POST",
      data: JSON.stringify(beneficiaryIdQuery),
      headers: headers.getHeaders(),
      url: headers.api,
    }
    axios(options)
      .then((res) => {
        if (res.data.errors) {
          _this.errorHandler(res.data.errors[0].message)
        } else {
          if (res.data.data.user.beneficiaries.length) {
            let beneficiaryId = res.data.data.user.beneficiaries[0].id,
              phonenumber = res.data.data.user.beneficiaries[0].phone.replace(
                "+",
                ""
              )
            _this.setState({
              beneficiaryId,
              phonenumber,
            })
            window.localStorage.setItem(`beneficiaryId`, beneficiaryId)
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  onDateChange(event) {
    this.setState({ dob: event.target.value })
  }
  onPhoneChange(event) {
    this.setState({ phonenumber: event.target.value })
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false,
    })
  }
  onHandleSsn() {
    if (this.state.showSsn) {
      return (
        <InputLabelState
          data={{
            name: `SSN`,
            type: `password`,
            helpText: this.state.ssnErrorText,
            errorStatus: this.state.ssnErrorStatus,
            defaultVisibility: 0,
            value: this.state.ssn,
          }}
          onChange={this.handleChange.bind(this)}
        />
      )
    } else {
      return (
        <InputLabelState
          data={{
            name: `ITIN`,
            type: `password`,
            helpText: this.state.itinErrorText,
            errorStatus: this.state.itinErrorStatus,
            defaultVisibility: 0,
            value: this.state.itin,
          }}
          onChange={this.handleChange.bind(this)}
        />
      )
    }
  }
  onHandleClick() {
    if (this.state.showSsn) {
      this.setState({ showSsn: false, ssn: "" })
    } else {
      this.setState({ showSsn: true, itin: "" })
    }
  }
  handleSubmit = (ev) => {
    ev.preventDefault()
    let _this = this //needed to avoid losing context during axios/fetch call
    let phone = this.state.phonenumber.replace(/-/gi, "")
    let legalnameErrorStatus = !validate.name.test(this.state.legalname)
    let dobErrorStatus = !validate.dob.test(this.state.dob)
    let phonenumberErrorStatus = !validate.phone.test(phone)
    let ssnErrorStatus = !validate.ssn.test(this.state.ssn)
    let itinErrorStatus = !validate.itin.test(this.state.itin)

    // make sure dob is in the past
    if (!dobErrorStatus) {
      const dobArr = this.state.dob.split("-")
      const now = Date.now()
      const dobDate = new Date(
        `${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`
      ).getTime()

      if (now <= dobDate) {
        dobErrorStatus = true
      }
    }

    this.setState({
      legalnameErrorStatus,
      dobErrorStatus,
      phonenumberErrorStatus,
      ssnErrorStatus,
      itinErrorStatus,
    })
    if (this.state.ssn) {
      if (
        !legalnameErrorStatus &&
        !dobErrorStatus &&
        !phonenumberErrorStatus &&
        !ssnErrorStatus
      ) {
        _this.setState({ buttonSubmitted: true })
        let dobArr = _this.state.dob.split("-")
        if (this.state.beneficiaryId) {
          let beneficiaryUpdate = {
            query: `mutation($updateBeneficiaryUserInput: UpdateBeneficiaryUserInput!){
          updateBeneficiaryUser(input: $updateBeneficiaryUserInput){
            user {
              id
              name
              birthdate
            }
          }
        }`,
            variables: {
              updateBeneficiaryUserInput: {
                beneficiaryUserId: _this.state.beneficiaryId,
                user: {
                  name: _this.state.legalname,
                  ssn: _this.state.ssn,
                  birthdate: `${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`,
                },
              },
            },
          }
          const updateOptions = {
            method: "POST",
            data: JSON.stringify(beneficiaryUpdate),
            headers: headers.getHeaders(),
            url: headers.api,
          }
          axios(updateOptions)
            .then((result) => {
              if (result.data.errors) {
                _this.errorHandler(result.data.errors[0].message)
              } else {
                if (typeof window !== `undefined`) {
                  localStorage.setItem("childName", _this.state.legalname)
                  localStorage.setItem("childDob", _this.state.dob)
                  this.props.setNextStep("employment")
                  this.props.navigate(this.props.nextForm)
                }
              }
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          let createBeneficiary = {
            query: `mutation($createBeneficiaryUserInput: CreateBeneficiaryUserInput!){
            createBeneficiaryUser(input: $createBeneficiaryUserInput){
              user {
                id,
                token
              }
            }
          }`,
            variables: {
              createBeneficiaryUserInput: {
                user: {
                  phone: _this.state.phonenumber,
                  name: _this.state.legalname,
                  ssn: _this.state.ssn,
                  birthdate: `${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`,
                },
              },
            },
          }
          const createOptions = {
            method: "POST",
            data: JSON.stringify(createBeneficiary),
            headers: headers.getHeaders(),
            url: headers.api,
          }
          axios(createOptions)
            .then((result) => {
              if (result.data.errors) {
                _this.errorHandler(result.data.errors[0].message)
              } else {
                window.localStorage.setItem(
                  `beneficiaryId`,
                  result.data.data.createBeneficiaryUser.user.id
                )
                if (typeof window !== `undefined`) {
                  localStorage.setItem("childName", _this.state.legalname)
                  localStorage.setItem("childDob", _this.state.dob)
                }
                this.props.setNextStep("employment")
                this.props.navigate("/parent-registration/employment")
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
    }
    if (this.state.itin) {
      if (
        !legalnameErrorStatus &&
        !dobErrorStatus &&
        !phonenumberErrorStatus &&
        !itinErrorStatus
      ) {
        _this.setState({ buttonSubmitted: true })
        let dobArr = _this.state.dob.split("-")
        if (this.state.beneficiaryId) {
          let beneficiaryUpdate = {
            query: `mutation($updateBeneficiaryUserInput: UpdateBeneficiaryUserInput!){
          updateBeneficiaryUser(input: $updateBeneficiaryUserInput){
            user {
              id
              name
              birthdate
            }
          }
        }`,
            variables: {
              updateBeneficiaryUserInput: {
                beneficiaryUserId: _this.state.beneficiaryId,
                user: {
                  name: _this.state.legalname,
                  ssn: _this.state.itin,
                  birthdate: `${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`,
                },
              },
            },
          }
          const updateOptions = {
            method: "POST",
            data: JSON.stringify(beneficiaryUpdate),
            headers: headers.getHeaders(),
            url: headers.api,
          }
          axios(updateOptions)
            .then((result) => {
              if (result.data.errors) {
                _this.errorHandler(result.data.errors[0].message)
              } else {
                if (typeof window !== `undefined`) {
                  localStorage.setItem("childName", _this.state.legalname)
                  localStorage.setItem("childDob", _this.state.dob)
                  this.props.setNextStep("employment")
                  this.props.navigate(this.props.nextForm)
                }
              }
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          let createBeneficiary = {
            query: `mutation($createBeneficiaryUserInput: CreateBeneficiaryUserInput!){
            createBeneficiaryUser(input: $createBeneficiaryUserInput){
              user {
                id,
                token
              }
            }
          }`,
            variables: {
              createBeneficiaryUserInput: {
                user: {
                  phone: _this.state.phonenumber,
                  name: _this.state.legalname,
                  ssn: _this.state.itin,
                  birthdate: `${dobArr[2]}-${dobArr[0]}-${dobArr[1]}`,
                },
              },
            },
          }
          const createOptions = {
            method: "POST",
            data: JSON.stringify(createBeneficiary),
            headers: headers.getHeaders(),
            url: headers.api,
          }
          axios(createOptions)
            .then((result) => {
              if (result.data.errors) {
                _this.errorHandler(result.data.errors[0].message)
              } else {
                window.localStorage.setItem(
                  `beneficiaryId`,
                  result.data.data.createBeneficiaryUser.user.id
                )
                if (typeof window !== `undefined`) {
                  localStorage.setItem("childName", _this.state.legalname)
                  localStorage.setItem("childDob", _this.state.dob)
                }
                this.props.setNextStep("employment")
                this.props.navigate("/parent-registration/employment")
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
    }
  }

  errorHandler = (err) => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err),
    })
  }

  restrictDate = () => {
    var year = new Date().getFullYear().toString()
    return year
  }
  render() {
    return (
      <React.Fragment>
        <BackButton navigate={this.props.navigate} />
        <HeaderAndText data={this.props.headingData} />
        <div className="your-info-form">
          <form onSubmit={this.handleSubmit}>
            <InputLabelState
              data={{
                name: `legal name`,
                label: `Student's legal name`,
                type: `text`,
                helpText: this.state.legalnameErrorText,
                errorStatus: this.state.legalnameErrorStatus,
                defaultVisibility: 0,
                value: this.state.legalname,
              }}
              onChange={this.handleChange.bind(this)}
            />
            <InputPhone
              name="Student's phone number"
              value={this.state.phonenumber}
              helpText={this.state.phonenumberErrorText}
              defaultVisibility={0}
              errorStatus={this.state.phonenumberErrorStatus}
              onChange={this.onPhoneChange.bind(this)}
              isDisabled={this.state.beneficiaryToken ? true : false}
            />
            <InputDate
              name="Student's DOB"
              options={{
                blocks: [2, 2, 4],
                delimiter: "-",
                numericOnly: true,
              }}
              value={this.state.dob}
              helpText={this.state.dobErrorText}
              defaultVisibility={0}
              errorStatus={this.state.dobErrorStatus}
              onChange={this.onDateChange.bind(this)}
            />
            {this.onHandleSsn()}
            <p className="ssn-toggle" onClick={() => this.onHandleClick()}>
              {this.state.showSsn
                ? "Student has an ITIN"
                : "Student has an SSN"}
            </p>
            <HiddenErrorHandler error={this.state.generalError} />
            <FormButton
              onClick={() =>
                LAEJS.track("button_tap.support_student_child_info_next")
              }
            />
          </form>
        </div>
      </React.Fragment>
    )
  }
}

export default StudentInfoForm
